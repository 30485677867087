import { Injectable, TemplateRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SortEvent,MessageService } from 'primeng/api';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import * as FileSaver from 'file-saver';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'

const doc = new jsPDF();


@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  modalReference!: NgbModalRef;

  private rBack = new BehaviorSubject<string>("");
  routerBack = this.rBack.asObservable();
  private hTitle = new BehaviorSubject<string>("");
  hTMessage = this.hTitle.asObservable();
  //data: Array<Menu> = [];
  //public mACCIONES = new BehaviorSubject<Array<Menu>>([]);
  //menuAccion$ = this.mACCIONES.asObservable();
  //public mFAVORITOS = new BehaviorSubject<Array<Menu>>([]);
  //menuFavoritos$ = this.mFAVORITOS.asObservable();
  public arrayFavoritos = new BehaviorSubject<Array<any>>([]);
  arrayFavoritos$ = this.arrayFavoritos.asObservable();

  closeResult = '';
  toasts: any[] = [];

  constructor(private _snackBar: MatSnackBar, private modalService: NgbModal,) { }
  changesRBack(rback: string) {
    this.rBack.next(rback)
  }
  changeHeaderTitle(hTitle: string) {
    this.hTitle.next(hTitle);
  }
  // setMenuAccion(data: Array<any>) {
  //   this.data = data;
  //   this.mACCIONES.next(data);
  // }
  // setMenuFav(data: Array<any>) {
  //   this.mFAVORITOS.next(data);
  // }
  setFavoritos(data: Array<any>) {
    this.arrayFavoritos.next(data);
  }

  Ramdom6() {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }
  idGloba(value: string) {
    let cadena = value.slice(7, -7);
    return cadena;
  }
  informationUser(data: Array<any>) {
    localStorage.setItem('information',JSON.stringify(data));
  }
  get session(): string {
    return localStorage.getItem('session') || '';
  }
  get sessiot(): string {
    return localStorage.getItem('sessiot') || '';
  }
  get brand(): string {
    return localStorage.getItem('ib') || '';
  }
  get campaign(): string {
    return localStorage.getItem('ica') || '';
  }
  get getInformationUser(): string {
    return localStorage.getItem('information') || '';
  }
  get campaignName(): string {
    return localStorage.getItem('icaN') || '';
  }
  get navActiveCalendary(): string {
    return localStorage.getItem('nacldy') || '';
  }
  customerLS(customer: string) {
    localStorage.setItem('ic', customer);
  }
  brandLS(brand: string) {
    localStorage.setItem('ib', brand);
  }
  campaignLS(campaign: string) {
    localStorage.setItem('ica', campaign);
  }
  campaignNameLS(campaignName: string) {
    localStorage.setItem('icaN', campaignName);
  }
  navActiveCalendaryLS(navActiveCalendary: string) {
    localStorage.setItem('nacldy', navActiveCalendary);
  }
  openSnackBar(mensaje: string) {
    this._snackBar.open(mensaje, 'X', {
      duration: 3000
    });
  }

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }
  showToastSuccess() {
    var textOrTpl: string = 'Transacción completada';
    var options: any = { classname: 'bg-success text-white', delay: 3000 };
    this.toasts.push({ textOrTpl, ...options });
  }
  showToastSuccessDinamic(text:string) {
    var textOrTpl: string = text;
    var options: any = { classname: 'bg-success text-white', delay: 3000 };
    this.toasts.push({ textOrTpl, ...options });
  }

  remove(toast: any) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  formatDate(date: Date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  formatngbDPiIniDate(date: Date) {
    const today = date;

    const month = (today.getMonth() + 1);
    const year = today.getFullYear();
    const day = today.getDate();

    return { year: year, month: month, day: day };
  }
  formatngbDPSubmitDate(date: any) {
    const format = date;

    return format.year + '-' + format.month + '-' + format.day;
  }
  copyTextClipboard(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
  //MODAL
  OpenModal(content: any, size: string) {
    this.modalReference = this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: size });
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  cerrarModal() {
    this.modalReference.close();
  }
  //MODAL

  //Ordenar tabla columnas
  tableSort(event: SortEvent) {
    event.data!.sort((data1, data2) => {
      let value1 = data1[event.field!];
      let value2 = data2[event.field!];
      let result = null;

      if (value1 == null && value2 != null)
        result = -1;
      else if (value1 != null && value2 == null)
        result = 1;
      else if (value1 == null && value2 == null)
        result = 0;
      else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
      else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

      return (event.order! * result);
    });
  }
  //Exportar Archivos

  exportExcel(body:any,name:string ) {
    import("xlsx").then(xlsx => {
        const worksheet = xlsx.utils.json_to_sheet(body);
        const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, name);
    });
}

exportPdf(columns:any,body:any,name:string) {
  autoTable(doc,{
    columnStyles: { europe: { halign: 'center' } }, // European countries centered
    body: body,
    columns: columns,
  })
  doc.save(name+new Date().getTime()+'.pdf')
}

saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
}
}
