<nav class="sidebar-nav pt-3">
    <ul id="sidebarnav">
      <span class="badge bg-light text-dark" style="width:100%;">{{user?.SU_NOMBRE!}}</span>
        <!-- First level menu -->
        <!-- <li class="sidebar-item" [class.active]="showMenu === sidebarnavItem.title"
            *ngFor="let sidebarnavItem of sidebarnavItems"
            [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'"> -->

            <!-- <div class="" *ngIf="sidebarnavItem.extralink === true">{{sidebarnavItem.title}}</div> -->

            <!-- <a class="sidebar-link waves-effect waves-dark"
                [routerLink]="sidebarnavItem.class === '' ? [sidebarnavItem.path] : null"
                [ngClass]="[sidebarnavItem.class]" *ngIf="!sidebarnavItem.extralink;"
                (click)="addExpandClass(sidebarnavItem.title)"
                [routerLinkActive]="sidebarnavItem.submenu.length != 0 ? '' : 'active'">

                <mat-icon aria-hidden="false">{{sidebarnavItem.icon}}</mat-icon>
                <span class="hide-menu">{{sidebarnavItem.title}}</span>
            </a>
        </li> -->


          <li class="sidebar-item" *ngIf="permisos?.privileges[0]?.m_Dashboard==1">
              <a class="sidebar-link waves-effect waves-dark active" ng-reflect-ng-class="text-success" ng-reflect-router-link="/#/dashboard" href="/#/dashboard"><mat-icon role="img" aria-hidden="false" class="mat-icon notranslate material-icons mat-icon-no-color" data-mat-icon-type="font">home</mat-icon><span class="hide-menu">Dashboard</span>
            </a>
        </li>
        <li class="sidebar-item" *ngIf="permisos?.privileges[0]?.m_Usuarios==1">
            <a class="sidebar-link waves-effect waves-dark " ng-reflect-router-link="/#/users" href="/#/users"><mat-icon role="img" aria-hidden="false" class="mat-icon notranslate material-icons mat-icon-no-color" data-mat-icon-type="font">manage_accounts</mat-icon><span class="hide-menu">Usuarios</span></a><!--bindings={
            "ng-reflect-ng-if": "true"
          }--></li><li class="sidebar-item" *ngIf="permisos?.privileges[0]?.m_Catalogos==1">
            <a class="sidebar-link waves-effect waves-dark " ng-reflect-router-link="/#/component/catalogue" href="/#/component/catalogue">
              <mat-icon role="img" aria-hidden="false" class="mat-icon notranslate material-icons mat-icon-no-color" data-mat-icon-type="font">list</mat-icon>
              <span class="hide-menu">Catalogos</span>
            </a><!--bindings={
            "ng-reflect-ng-if": "true"
          }--></li><li class="sidebar-item" *ngIf="permisos?.privileges[0]?.m_Asuntos==1"><a class="sidebar-link waves-effect waves-dark " ng-reflect-router-link="/#/component/matter" href="/#/component/matter"><mat-icon role="img" aria-hidden="false" class="mat-icon notranslate material-icons mat-icon-no-color" data-mat-icon-type="font">home_repair_service</mat-icon><span class="hide-menu">Asuntos</span></a><!--bindings={
            "ng-reflect-ng-if": "true"
          }--></li><li class="sidebar-item" *ngIf="permisos?.privileges[0]?.m_Actividades==1"><a class="sidebar-link waves-effect waves-dark " ng-reflect-router-link="/#/component/activities" href="/#/component/activities"><mat-icon role="img" aria-hidden="false" class="mat-icon notranslate material-icons mat-icon-no-color" data-mat-icon-type="font">fact_check</mat-icon><span class="hide-menu">Actividades</span></a><!--bindings={
            "ng-reflect-ng-if": "true"
          }--></li>

        <li class="text-center p-40 upgrade-btn">
            <a (click)="confirm()" class="btn btn-block btn-danger">Cerrar Sesión</a>
        </li>
    </ul>
</nav>

<p-confirmDialog header="Confirmación" icon="pi pi-exclamation-triangle"></p-confirmDialog>

