import { Component, EventEmitter, Output } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
//declare var $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent {
  user:any;
  @Output()
  toggleSidebar = new EventEmitter<void>();

  public showSearch = false;

  constructor( private gS:GlobalService) {
    setTimeout(() => {
      console.log(this.gS.getInformationUser);
      this.user=JSON.parse(this.gS.getInformationUser);
    }, 5000);
  }
}
