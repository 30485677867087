import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { UsuariosService } from 'src/app/services/usuarios.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {
  constructor( private authService:UsuariosService, private router:Router) { }
  verificadorLogin()
  {
    
    return this.authService.verificacionAutenticacion().
      pipe(
        tap(
          estaAutenticado=>{
            
            if (!estaAutenticado) {
              console.log('sin auth'+estaAutenticado);
              this.router.navigate(['/auth/account']);
            }
          }
        )
      );
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      // if (this.authService.auth.id)
      // {
      //   return true;
      // }
      // else
      // {
      //   return false;
      // }
      
     return  this.verificadorLogin();
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      // console.log(route);
      // console.log(segments);
      // if (this.authService.auth.id)
      // {
      //   return true;
      // }
      // else
      // {
      //   return false;
      // }
      return this.verificadorLogin();
  }
}
