import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { Usersroutes } from './users-routing.module';
import { UsersComponent } from './users.component';
import { RouterModule } from '@angular/router';
import { PrimengModule } from '../primeng/primeng.module';


@NgModule({
  declarations: [UsersComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(Usersroutes),
    PrimengModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class UsersModule { }
