<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-left mr-auto">
    <!-- <li class="nav-item d-none d-md-block d-lg-none">
        <a (click)="toggleSidebar.emit()" class="nav-link sidebartoggler waves-effect waves-light" href="javascript:void(0)">
            <i class="mdi mdi-menu font-24"></i>
        </a>
    </li> -->

</ul>
<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-right">
    <!-- ============================================================== -->
    <!-- Search -->
    <!-- ============================================================== -->
    <li class="nav-item dropdown  mt-auto mb-auto" ngbDropdown>
        <a ngbDropdownToggle class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic"
        href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="pi pi-bell mr-4 p-text-secondary position-relative" pBadge style="font-size: 1.5rem" value="2">
            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" style="font-size: 8pt;">
                99+
              </span>
        </i>
    </a>
        
        <div class="dropdown-menu user-dd" ngbDropdownMenu>
            <table class="table table-sm table-dark table-hover">
                <tbody>
                    <tr>
                        <td>
                            
                            <span><span class="bg-danger rounded-circle" style="width: 10px; height: 10px; display: block;">
                            </span> 2022-03-18</span>
                        </td>
                        <td>Mensaje de prueba</td>
                    </tr>
                    <tr>
                        <td>2022-03-17</td>
                        <td>Mensaje de prueba1</td>
                    </tr>
                </tbody>
              </table>

              <a class="dropdown-item" href="javascript:void(0)">
                <i class="ti-email m-r-5 m-l-5"></i>All Messages</a>
        </div>
        
    </li>
    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->
    <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
        <a ngbDropdownToggle class="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic"
            href="javascript:void(0)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img [src]="user?.SU_FOTO_URL!" alt="user" class="rounded-circle" width="31" height="31">
        </a>
        <div class="dropdown-menu user-dd" ngbDropdownMenu>
            <a class="dropdown-item" href="javascript:void(0)">
                <i class="ti-user m-r-5 m-l-5"></i> My Profile</a>
            <a class="dropdown-item" href="javascript:void(0)">
                <i class="ti-wallet m-r-5 m-l-5"></i> My Balance</a>
            <a class="dropdown-item" href="javascript:void(0)">
                <i class="ti-email m-r-5 m-l-5"></i> Inbox</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="javascript:void(0)">
                <i class="ti-settings m-r-5 m-l-5"></i> Account Setting</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="javascript:void(0)">
                <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a>
            <div class="dropdown-divider"></div>
            <div class="p-l-30 p-10">
                <a href="javascript:void(0)" class="btn btn-sm btn-success btn-rounded">View Profile</a>
            </div>
        </div>
    </li>
    <!-- ============================================================== -->
    <!-- User profile and search -->
    <!-- ============================================================== -->
</ul>