import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Auth } from '../account/interfaces/auth.interfaces';
import { ConnectionService } from './connection.service';
import { Observable,of } from 'rxjs';
import { GlobalService } from './global.service';
import { DataAreas, DataUsers, MessageUsers } from '../interface/users.interface';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {
  private _auth!: Auth;

  get auth(): Auth {
    return { ... this._auth! }
  }
  constructor(private http: HttpClient, private cS: ConnectionService, private gS: GlobalService) { }

  guardarLocalStorage(token: string , id:any) {
    const id_start = this.gS.Ramdom6() + '-';
    const id_end = '-' + this.gS.Ramdom6();
    localStorage.setItem('session',id_start+ token+id_end);
    localStorage.setItem('sessiot',id_start+ id+id_end);
  }
  
  logout()
  {
    localStorage.removeItem('session');
    localStorage.removeItem('sessiot');
    // localStorage.removeItem('ib');
    // localStorage.removeItem('ica');
    // localStorage.removeItem('icaN');
  }
  getUsers()
  {
    const url = `${this.cS.baseurl()}/api/v1/users`;
    return this.http.get<any[]>(url, this.cS.httpOptions);
  }
  getUsersSingle()
  {
    const url = `${this.cS.baseurl()}/api/v1/users/${parseInt(this.gS.idGloba(this.gS.sessiot))}`;
    return this.http.get<any[]>(url, this.cS.httpOptions);
  }
  getCatalogue(id:number)
  {
    const url = `${this.cS.baseurl()}/api/v1/catalogos/catalogue/${id}`;
    return this.http.get<any[]>(url, this.cS.httpOptions);
  }
  updateUser(data:{name:string, email:string, id:number,adscrition: string, marketstall: string, privileges: string, 
    permissions: string, status: string, activation: string, ua: string})
  {
    let datas:any={
      name:data.name,
      email:data.email,
      id:data.id
    }
    const url = `${this.cS.baseurl()}/api/v1/users/${data.id}`;
    return this.http.put<any[]>(url,data, this.cS.httpOptions);
  }
  createUser1(user:DataUsers)
  {
    let data:any={
      "name":user.SU_NOMBRE,
      "username":user.SU_USUARIO,
      "password":user.password,
      "email":user.SU_CORREO,
      "adscrition":user.SU_ADSCRIPCION_FK,
      "marketstall":user.SU_PUESTO_FK,
      "privileges":user.SU_PRIVILEGIOS_FK,
      "permissions":user.SU_PERMISOS_FK,
      "status":user.SU_ESTATUS_FK,
      "activation":1,
      "photo":"http://sistemalegal.spem.com.mx/3075582.jpeg",
      "useralter":this.gS.idGloba(this.gS.sessiot)
    }
    
    const body=JSON.stringify(data);

    const url = `${this.cS.baseurl()}/user/create`;
    return this.http.post(url, data, this.cS.httpOptions);
  }
  createUser(data: { name: string, username: string, password: string, email: string,
    adscrition: string, marketstall: string, privileges: string, 
    permissions: string, status: string, activation: string, photo: string, useralter: string }) {
    const url = `${this.cS.baseurl()}/api/v1/users/add`;
    return this.http.post(url, data, this.cS.httpOptions);
  }
  disableUser(id:number)
  {
    const url = `${this.cS.baseurl()}/api/v1/users/disable/${id}`;
    return this.http.put<any[]>(url,id, this.cS.httpOptions);
  }
//   getUsers() {
//     const url = `${this.cS.baseurl()}/api/v1/users`;
//     return this.http.get<any>(url,this.cS.httpOptions)
//         .toPromise()
//         .then(user => <DataUsers[]>user.message.users.data, area => <DataAreas[]>area.message.areas)
//         .then(datauser => { return datauser; },dataarea => { return dataarea; });
// }
  validateCredentials(data: { username: string, password: string }) {
    const url = `${this.cS.baseurl()}/login`;
    return this.http.post(url, data);
  }
  verificacionAutenticacion():Observable<boolean>
  {

    if (!localStorage.getItem('session')) {
      return of(false) ;
    }
    else{
      return this.getUserOne();
    }
    
    
  }
   saludos(){
    
  }
  getUserOne(){
    const url = `${this.cS.baseurl()}/api/v1/users/${parseInt(this.gS.idGloba(this.gS.sessiot)) }`;
    return  this.http.get<any[]>(url,  this.cS.autorization()).
    pipe
    (
      map(
        auth=>{
          let a = JSON.stringify(auth);
          const json = JSON.parse(a!) || [];
          this._auth=json; 
          return true;
        }
      )
    );
  }
  getUserFratures()
  {
    const url = `${this.cS.baseurl()}/api/v1/users/features/`;
    return this.http.get<any[]>(url, this.cS.autorization());
  }
}
