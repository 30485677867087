import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './account/guards/auth.guard';

import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

import { FullComponent } from './layouts/full/full.component';

export const Approutes: Routes =[
  {
    path: 'auth',
    component: AuthLayoutComponent,
    children: [
      //{ path: '', redirectTo: '/account', pathMatch: 'full' },
      {
        path: "account",
        loadChildren: () =>
          import("./account/account.module").then(m => m.AccountModule)
      },
      // {
      //   path: "error",
      //   loadChildren: () =>
      //     import("./error/error.module").then(m => m.ErrorModule)
      // }
    ]
  },
  {
    path: '',
    component: FullComponent,
    children: [
      //{ path: '', redirectTo: '/dashboard', pathMatch: 'full' },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canLoad:[AuthGuard],
        canActivate:[AuthGuard]
      },
      {
        path: 'component',
        loadChildren: () => import('./component/component.module').then(m => m.ComponentsModule),
        canLoad:[AuthGuard],
        canActivate:[AuthGuard]
      }
      ,
      {
        path: 'users',
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
        canLoad:[AuthGuard],
        canActivate:[AuthGuard]
      }
    ]
  }
  
];
@NgModule({
  imports: [RouterModule.forRoot(Approutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

