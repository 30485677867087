import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { Md5 } from 'ts-md5';
import { GlobalService } from './global.service';
const md5 = new Md5();

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {
  private baseUrl:string=environment.baseUrl;
  private token:string=environment.token;
  private tokenSession:string=this.gS.session;
  constructor(private gS: GlobalService) { }

  signConnection() {
    const timestamp = Math.floor(new Date().getTime()/1000.0);
     let str = `${timestamp}${this.token}`;
    let auth = Md5.hashStr(str);
    
    let sign =`${timestamp}-${auth}`;
    return sign;
  }

  signConnection1() {
    let sign =this.tokenSession;
    return sign;
  }
autorization(){
  let httpOptions:any;
  return  httpOptions = {
      
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorizat:  this.gS.idGloba(localStorage.getItem('session') || '')
    })
  };
}
   
   httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorizat:  this.gS.idGloba(this.signConnection1())
    })
  };
  httpOptions1 = {
    headers: new HttpHeaders({
      'Content-Type':  'application/x-www-form-urlencoded',
      
      Authorizat: this.gS.idGloba(this.signConnection1())
    })
  };
   httpOptionsBlob = {
    responseType: 'blob' as 'json',
    headers: new HttpHeaders({
      Authorizat: this.signConnection()
    })
  };

  baseurl()
  {
    return this.baseUrl;
  }
}
