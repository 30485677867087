<div class="row">

    <div class="col-12 card">
        <a pButton pRipple icon="pi pi-arrow-left" class="p-button-rounded p-button-primary m-1" href="/#/dashboard"></a>
        <p-tabView>
            <p-tabPanel header="Usuarios">
                <div class="card">
                    <h4>Usuarios</h4>
                    
                    <p-table #dt1 [value]="users" dataKey="SU_ID" responsiveLayout="scroll"
                        [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" [loading]="loading"
                        [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
                        [globalFilterFields]="['SU_NOMBRE','SU_USUARIO','AAR_AREA']">
                        <ng-template pTemplate="caption">
                            <div class="row">
                                <div class="col-3 col-md-4 ps-1 pe-1">
                                    <button (click)="editUser()" type="button" class="btn btn-rounded btn-xs btn-success"><span class="p-button-icon p-button-icon-left pi pi-plus" aria-hidden="true"></span> New</button>
                                </div>
                                <div class="col-3 col-md-4 ps-1 pe-1">
                                    <button (click)="clear(dt1)" type="button" class="btn btn-rounded btn-xs  btn-outline-primary"><span class="pi pi-filter-slash" aria-hidden="true"></span> Clear</button>
                                </div>
                                <div class="col-6 col-md-4 ps-1 pe-1">
                                    <span class="p-input-icon-left ms-3">
                                        <i class="pi pi-search"></i>
                                        <input pInputText class="form-control form-control-sm" type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')" placeholder="Buscador" />
                                    </span>
                                </div>
                            </div>
                            
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th>
                                    <div class="p-d-flex p-jc-between p-ai-center">
                                        Nombre
                                        <p-columnFilter
                                          type="text"
                                          field="SU_NOMBRE"
                                          display="menu"
                                        ></p-columnFilter>
                                      </div>
                                </th>
                                <th>
                                    <div class="p-d-flex p-jc-between p-ai-center">
                                        Usuario
                                        <p-columnFilter type="text" field="SU_USUARIO" display="menu"></p-columnFilter>
                                    </div>
                                </th>
                                <th>
                                    <div class="flex justify-content-center align-items-center">
                                        Areas
                                        <p-columnFilter field="representative" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
                                            <ng-template pTemplate="header">
                                                <div class="px-3 pt-3 pb-0">
                                                    <span class="font-bold">Filtrar Por Area</span>
                                                </div>
                                            </ng-template>
                                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                                <p-multiSelect [ngModel]="value" [options]="representatives" placeholder="Any" (onChange)="filter($event.value)" optionLabel="AAR_AREA">
                                                    <ng-template let-option pTemplate="item">
                                                        <div class="p-multiselect-representative-option">
                                                            <span class="ml-1">{{option.AAR_AREA}}</span>
                                                        </div>
                                                    </ng-template>
                                                </p-multiSelect>
                                            </ng-template>
                                        </p-columnFilter>
                                    </div>
                                </th>
                                <th>
                                    <div class="p-d-flex p-jc-between p-ai-center">
                                        Correo
                                        <p-columnFilter type="text" field="SU_CORREO" display="menu"></p-columnFilter>
                                    </div>
                                </th>
                                <th>
                                    <div class="p-d-flex p-jc-between p-ai-center">
                                        Puesto
                                        <p-columnFilter type="text" field="AP_PUESTO" display="menu"></p-columnFilter>
                                    </div>
                                </th>
                                <th>
                                    <div class="p-d-flex p-jc-between p-ai-center">
                                        Estatus
                                        <p-columnFilter type="text" field="AEU_ESTATUS" display="menu"></p-columnFilter>
                                    </div>
                                </th>
                                <th></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-users>
                            <tr>
                                <td style="min-width: 150px;">
                                    <img [alt]="users.SU_NOMBRE" src="{{users.SU_FOTO_URL}}" width="25" height="25" class="rounded-circle" style="vertical-align: middle" />
                                      <span class="image-text">{{users.SU_NOMBRE}}</span>
                                </td>
                                <td>
                                    <span class="image-text">{{users.SU_USUARIO}}</span>
                                </td>
                                <td>
                                    <span class="image-text">{{users.AAR_AREA}}</span>
                                </td>
                                <td>
                                    <span class="image-text">{{users.SU_CORREO}}</span>
                                </td>
                                <td>
                                    <span class="image-text">{{users.AP_PUESTO}}</span>
                                </td>
                                <td>
                                    
                                    <span [class]="'prime-badge status-success'" *ngIf="users.SU_ESTATUS_FK==1">{{users.ac_name | titlecase}}</span>
                                    <span [class]="'prime-badge status-danger'" *ngIf="users.SU_ESTATUS_FK==2">{{users.ac_name | titlecase}}</span>
                                </td>
                                <td>
                                    <button pButton pRipple icon="pi pi-pencil" class="p-button-rounded p-button-success m-1" (click)="editUser(users)"></button>
                                    <button pButton pRipple icon="pi pi-trash" class="p-button-rounded p-button-warning m-1" (click)="deleteUser(users)"></button>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="7">No users found.</td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </p-tabPanel>
            <!-- <p-tabPanel header="Header 2">
                Content 2
            </p-tabPanel>
            <p-tabPanel header="Header 3">
                Content 3
            </p-tabPanel> -->
        </p-tabView>
    </div>
    <div class="col-12">
        <p-dialog [(visible)]="userDialog" [style]="{width: '450px'}" header="Detalle de Usuario" [modal]="true" styleClass="p-fluid">
            <ng-template pTemplate="content">
                <div class="row">
                    <div class="col-10">
                        <input type="hide" [src]="user.SU_FOTO" style="display: none;">
                        <img height="70" width="70" [src]="user.SU_FOTO_URL" [alt]="user.SU_NOMBRE" class="rounded d-block rounded-circle">
                    </div>
                    <div class="col-2 mb-auto mt-auto text-center">
                        <form autocomplete="off" [formGroup]="formPrifie">
                            <button pButton pRipple icon="pi pi-upload" class="p-button-rounded p-button-success m-1" (click)="fileUploadImg.click()"></button>
                            <input type="file" (change)="cambiarImagen($event)" #fileUploadImg hidden="true">
                            <input type="hide" formControlName="mediaID" style="display: none;">
                        </form>
                    </div>
                </div>
                
                <div class="field" >
                    <label for="SU_USUARIO" class="semibold mb-2">Usuario</label>
                    <input type="text" pInputText id="SU_USUARIO" [(ngModel)]="user.SU_USUARIO" required autofocus />
                    <small class="p-error" *ngIf="submitted && !user.SU_USUARIO">Username is required.</small>
                </div>
                <div class="field" >
                    <label for="password" class="semibold mb-2">Contraseña</label>
                    <input type="password" pInputText id="password" [(ngModel)]="user.password" required />
                    <small class="p-error" *ngIf="submitted && !password">Contraseña is required.</small>
                </div>

                <div class="field">
                    <label for="SU_NOMBRE" class="semibold mb-2">Nombre Completo</label>
                    <input type="text" pInputText id="SU_NOMBRE" [(ngModel)]="user.SU_NOMBRE" required />
                    <small class="p-error" *ngIf="submitted && !user.SU_NOMBRE">SU_NOMBRE is required.</small>
                </div>
                <div class="field">
                    <label for="SU_CORREO" class="semibold mb-2">Correo</label>
                    <input type="text" pInputText id="SU_CORREO" [(ngModel)]="user.SU_CORREO" required />
                    <small class="p-error" *ngIf="submitted && !user.SU_CORREO">SU_CORREO is required.</small>
                </div>

                <div class="field">
                    <label for="slArea">Area</label>
                    <select class="form-select" [(ngModel)]="user.SU_ADSCRIPCION_FK" aria-label="Default select example" id="slArea" required>
                        <option value="-1" >Selecciona una opción...</option>
                        <option *ngFor="let item of areas;" [value]="item.id">{{item.value}}</option>
                    </select>
                    <small class="p-error" *ngIf="submitted && !user.SU_ADSCRIPCION_FK">Puesto is required.</small>
                </div>

                <div class="field">
                    <label for="slPuesto">Puesto</label>
                    <select class="form-select" [(ngModel)]="user.SU_PUESTO_FK" aria-label="Default select example" id="slPuesto" required>
                        <option value="-1" >Selecciona una opción...</option>
                        <option *ngFor="let item of puestos;" [value]="item.id">{{item.value}}</option>
                    </select>
                    <small class="p-error" *ngIf="submitted && !user.SU_PUESTO_FK">Puesto is required.</small>
                </div>

                <div class="field">
                    <label for="slPrivilegio">Privilegios</label>
                    <select class="form-select" [(ngModel)]="user.SU_PRIVILEGIOS_FK" aria-label="Default select example" id="slPrivilegio" >
                        <option value="-1">Selecciona una opción...</option>
                        <option *ngFor="let item of privilegios;" [value]="item.id">{{item.value}}</option>
                    </select>
                </div>

                <div class="field">
                    <label for="slPermisos">Permisos</label>
                    <select class="form-select" [(ngModel)]="user.SU_PERMISOS_FK" aria-label="Default select example" id="slPermisos" >
                        <option value="-1">Selecciona una opción...</option>
                        <option *ngFor="let item of permisos;" [value]="item.id">{{item.value}}</option>
                    </select>
                </div>

                <div class="field">
                    <label for="slEstatus">Estatus</label>
                    <select class="form-select" [(ngModel)]="user.SU_ESTATUS_FK" aria-label="Default select example" id="slEstatus" >
                        <option value="-1">Selecciona una opción...</option>
                        <option *ngFor="let item of statuses;" [value]="item.id">{{item.value}}</option>
                    </select>
                </div>
        
                
            </ng-template>
        
            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancel" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></button>
                <button pButton pRipple label="Save" icon="pi pi-check" class="p-button-text" (click)="saveUser()"></button>
            </ng-template>
        </p-dialog>
    </div>
</div>

<p-toast></p-toast>
<p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
