
import { Component, OnInit, ViewChild } from '@angular/core';
import { Catalogue, DataAreas, DataUsers, MessageUsers } from '../interface/users.interface';
import { UsuariosService } from '../services/usuarios.service';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import Swal from 'sweetalert2';
import { sha512 } from 'js-sha512';
import { GlobalService } from '../services/global.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { FileuploadService } from '../services/fileupload.service';

const Swa = Swal.mixin({
  heightAuto: false
});

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],

})
export class UsersComponent implements OnInit {
  users: DataUsers[] = [];
  user!: DataUsers;
  areas: Catalogue[] = [];
  puestos: Catalogue[] = [];
  privilegios: Catalogue[] = [];
  permisos: Catalogue[] = [];
  userStatus: Catalogue[] = [];
  loading: boolean = true;
  userDialog?: boolean;
  submitted!: boolean;
  activityValues: number[] = [0, 100];
  fileToUpload!: File;
  formPrifie!: FormGroup;
  representatives: DataAreas[] = [];

  statuses: any[] = [
    { label: 'Unqualified', value: 'unqualified' },
    { label: 'Qualified', value: 'qualified' },
    { label: 'New', value: 'new' },
    { label: 'Negotiation', value: 'negotiation' },
    { label: 'Renewal', value: 'renewal' },
    { label: 'Proposal', value: 'proposal' }
  ];
  constructor(private usersService: UsuariosService,
    private messageService: MessageService, private confirmationService: ConfirmationService,
    private gS: GlobalService, private fUS:FileuploadService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.sGetUsers();
    this.startForms();
    this.statuses;

    this.puestos = this.scatalogue(0);
    this.areas = this.scatalogue(1);
    this.privilegios = this.scatalogue(2);
    this.permisos = this.scatalogue(3);
    this.statuses = this.scatalogue(4);

    
  }
  startForms() {
    this.formPrifie = this.fb.group(
      {
        mediaID: ['F19LrC-0768ed582638928ce3e70e532235db63-dXsx97']
      });
      
    }
  sGetUsers() {
    this.usersService.getUsers()
      .subscribe((resp: any) => {
        if (resp.code === 200) {
          this.loading = false;
          this.users = resp.message.users;
          this.representatives = resp.message.areas;
        }
        else {
          this.loading = false;
          Swa.fire({ icon: 'error', text: resp.code + ': ' + resp.status });
        }
      });
  }
  clear(table: any) {
    table.clear();
  }
  editUser(users: DataUsers) {

    this.user = { ...users };

    this.userDialog = true;
    if (!users) {
      const data: DataUsers = {
        SU_ID: 0, SU_NOMBRE: "", SU_USUARIO: "", SU_CORREO: "", SU_ADSCRIPCION_FK: -1,
        AAR_AREA: "", SU_PUESTO_FK: -1, AP_PUESTO: "", SU_PRIVILEGIOS_FK: -1, SP_NOMBRE: "", SU_PERMISOS_FK: -1, SPE_NOMBRE: "",
        SU_ESTATUS_FK: -1, ac_name: "", SU_ACTIVACION: "0", SU_FOTO: "",SU_FOTO_URL:"", SU_FC: "", SU_FA: "", SU_UA: -1
      };
      this.user = { ...data };
    }
    //console.log(users);
  }
  deleteUser(user: DataUsers) {
    this.confirmationService.confirm({
      message: '¿Estás seguro de desactivar la cuenta de, ' + user.SU_NOMBRE + '?',
      header: 'Confirmar',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {

        //this.users = this.users.filter(val => val.SU_ID !== user.SU_ID);
        //console.log(this.users);
        this.sDisableUser(user.SU_ID);
        //let data: DataUsers;
        //this.user = { ...data! };
      }
    });
  }
  hideDialog() {
    this.userDialog = false;
    this.submitted = false;
  }
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0)!;
}

  saveUser() {
    this.submitted = true;

    if (this.user.SU_NOMBRE.trim() || this.user.SU_CORREO.trim()) {
      if (this.user.SU_ID) {
        this.sUdateUser(this.user);
      }
      else {
          this.fUS.UploadFileok(this.fileToUpload!)
            .subscribe((resp: any) => {
              if (resp.code == 201) {
                this.user.SU_FOTO=resp.message.id;
                this.sCreateUser(this.user);
              }
              else {
                Swa.fire({ icon: 'error', text: resp.status });
                
              }
            });
      }
    }
  }
  cambiarImagen(event: any) {
    var file = event.target.files[0];
    this.fileToUpload = file;
    console.log(file);

    const reader = new FileReader();
    reader.onload = () => {
      this.user.SU_FOTO_URL = reader.result as string;
    }
    reader.readAsDataURL(file)
    //this.uploadimg();
  }
  cambiarImagen1(event: any) {
  var file=event.files[0];
  // console.log(file);
  // console.log(event);
  }
  cifrar() {

  }

  sUdateUser(user: DataUsers) {
    let data: any = {
      id: user.SU_ID,
      name: user.SU_NOMBRE,
      email: user.SU_CORREO,
      adscrition: user.SU_ADSCRIPCION_FK,
      marketstall: user.SU_PUESTO_FK,
      privileges: user.SU_PRIVILEGIOS_FK,
      permissions: user.SU_PERMISOS_FK,
      status: user.SU_ESTATUS_FK,
      activation: user.SU_ACTIVACION,
      useralter: this.gS.idGloba(this.gS.sessiot)
    }
    this.usersService.updateUser(data)
      .subscribe((resp: any) => {
        if (resp.code == 200) {
          this.loading = false;
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'User Updated', life: 3000 });
          this.userDialog = false;
          this.users = [...this.users];

          let data: DataUsers;
          this.user = { ...data! };
          this.sGetUsers();
        }
        else {
          this.loading = false;
          Swa.fire({ icon: 'error', text: resp.code + ': ' + resp.status });
        }
      });
  }
  sCreateUser1(user: DataUsers) {

    this.usersService.createUser1(user)
      .subscribe((resp: any) => {
        if (resp.code == 200) {
          this.loading = false;
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'User Created', life: 3000 });
          this.userDialog = false;
          this.users = [...this.users];

          let data: DataUsers;
          this.user = { ...data! };
        }
        else {
          this.loading = false;
          Swa.fire({ icon: 'error', text: resp.code + ': ' + resp.status });
        }
      });
  }
  sCreateUser(user: DataUsers) {
    let data: any = {
      name: user.SU_NOMBRE,
      username: user.SU_USUARIO,
      password:sha512(user.password!),
      email: user.SU_CORREO,
      adscrition: user.SU_ADSCRIPCION_FK,
      marketstall: user.SU_PUESTO_FK,
      privileges: user.SU_PRIVILEGIOS_FK,
      permissions: user.SU_PERMISOS_FK,
      status: user.SU_ESTATUS_FK,
      activation: user.SU_ACTIVACION,
      photo: user.SU_FOTO,
      useralter: this.gS.idGloba(this.gS.sessiot)
    }
    this.usersService.createUser(data)
      .subscribe((resp: any) => {
        if (resp.code == 201) {
          this.loading = false;
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'User Created', life: 3000 });
          this.userDialog = false;
          this.users = [...this.users];

          let data: DataUsers;
          this.user = { ...data! };
          this.sGetUsers();
        }
        else {
          this.loading = false;
          Swa.fire({ icon: 'error', text: resp.code + ': ' + resp.status });
        }
      });
  }
  sDisableUser(id: number) {
    this.usersService.disableUser(id)
      .subscribe((resp: any) => {
        if (resp.code == 200) {
          this.loading = false;
          this.sGetUsers();
          this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'User Disabled', life: 3000 });
        }
        else {
          this.loading = false;
          Swa.fire({ icon: 'error', text: resp.code + ': ' + resp.status });
        }
      });
  }

  scatalogue(id: number): Catalogue[] {
    const array: Catalogue[] = [];
    this.usersService.getCatalogue(id)
      .subscribe((resp: any[]): any => {
        let a = JSON.stringify(resp);
        const json = JSON.parse(a!) || [];
        // console.log(json);
        if (json.code == 200) {
          for (const it of json.message) {
            array.push({ id: it.id, value: it.value });
          }
        }
        else {
          this.loading = false;
          Swa.fire({ icon: 'error', text: json.code + ': ' + json.status });
        }
      });
    return array;
  }
  onBasicUpload(event:File) {
    console.log(event);
    this.messageService.add({severity: 'info', summary: 'Success', detail: 'File Uploaded with Basic Mode'});
}
  // getCombo(id: number): SelectValue[] {
  //   const array: SelectValue[] = [];
  //   this.sCat.getCatalogue(id)
  //     .subscribe((resp: any[]): any => {
  //       let a = JSON.stringify(resp);
  //       const json = JSON.parse(a!) || [];
  //       if (json.internal_code == 0) {
  //         for (const it of json.response_object.values) {
  //           array.push({ id: it.id, value: it.value, child_catalogue_id: it.child_catalogue_id, parent_value_id: it.parent_value_id });
  //         }
  //       }
  //       else { console.log(json.message + ' : ' + json.internal_code); }
  //     });
  //   return array;
  // }

  createId(): string {
    let id = '';
    var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (var i = 0; i < 5; i++) {
      id += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return id;
  }
}
