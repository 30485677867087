
import { CommonModule } from '@angular/common';
import { TabViewModule } from 'primeng/tabview';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressBarModule } from 'primeng/progressbar';
import {TableModule} from 'primeng/table';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {ToastModule} from 'primeng/toast';
import {SliderModule} from 'primeng/slider';
import {ContextMenuModule} from 'primeng/contextmenu';
import {ButtonModule} from 'primeng/button';
import {DropdownModule} from 'primeng/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import {FileUploadModule} from 'primeng/fileupload';
import {AvatarModule} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import { RippleModule } from 'primeng/ripple';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {BadgeModule} from 'primeng/badge';
import {SpeedDialModule} from 'primeng/speeddial';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    
  ],
  providers:[
    MessageService,
    ConfirmationService
  ],
  exports:[
    TabViewModule,
    TableModule,
    CalendarModule,
		SliderModule,
		DialogModule,
		MultiSelectModule,
		ContextMenuModule,
		DropdownModule,
		ButtonModule,
		ToastModule,
    InputTextModule,
    ProgressBarModule,
    ConfirmDialogModule,
    FileUploadModule,
    AvatarModule,
    AvatarGroupModule,
    RippleModule,
    AvatarModule,
    AvatarGroupModule,
    InputTextareaModule,
    BadgeModule,
    SpeedDialModule
  ]
})
export class PrimengModule { }
