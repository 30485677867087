import { RouteInfo } from './sidebar.metadata';

export const ROUTES: RouteInfo[] = [
  {
    path: '',
    title: 'Personal',
    icon: 'mdi mdi-dots-horizontal',
    class: 'nav-small-cap',
    extralink: true,
    submenu: []
  },
  {
    path: '/dashboard',
    title: 'Dashboard',
    icon: 'home',
    class: 'text-success',
    extralink: false,
    submenu: []
  },
  {
    path: '/users',
    title: 'Usuarios',
    icon: 'manage_accounts',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/component/catalogue',
    title: 'Catalogos',
    icon: 'list',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/component/matter',
    title: 'Asuntos',
    icon: 'home_repair_service',
    class: '',
    extralink: false,
    submenu: []
  },
  {
    path: '/component/activities',
    title: 'Actividades',
    icon: 'fact_check',
    class: '',
    extralink: false,
    submenu: []
  },
  // {
  //   path: '/component/card',
  //   title: 'Card Asuntos',
  //   icon: 'mdi mdi-blur-radial',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/accordion',
  //   title: 'Accordion Contratos',
  //   icon: 'mdi mdi-equal',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/alert',
  //   title: 'Alert Catalogos',
  //   icon: 'mdi mdi-message-bulleted',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/carousel',
  //   title: 'Carousel Clientes',
  //   icon: 'mdi mdi-view-carousel',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/dropdown',
  //   title: 'Dropdown Actividades',
  //   icon: 'mdi mdi-arrange-bring-to-front',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/modal',
  //   title: 'Modal',
  //   icon: 'mdi mdi-tablet',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/pagination',
  //   title: 'Pagination',
  //   icon: 'mdi mdi-backburger',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/poptool',
  //   title: 'Popover & Tooltip',
  //   icon: 'mdi mdi-image-filter-vintage',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/progressbar',
  //   title: 'Progressbar',
  //   icon: 'mdi mdi-poll',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/rating',
  //   title: 'Ratings',
  //   icon: 'mdi mdi-bandcamp',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/tabs',
  //   title: 'Tabs',
  //   icon: 'mdi mdi-sort-variant',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/timepicker',
  //   title: 'Timepicker',
  //   icon: 'mdi mdi-calendar-clock',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/buttons',
  //   title: 'Button',
  //   icon: 'mdi mdi-toggle-switch',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // },
  // {
  //   path: '/component/toast',
  //   title: 'Toast',
  //   icon: 'mdi mdi-alert',
  //   class: '',
  //   extralink: false,
  //   submenu: []
  // }
];
