import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConnectionService } from './connection.service';
import { GlobalService } from './global.service';

const base_url = 'https://cleverflowmediauploader.azurewebsites.net';

@Injectable({
  providedIn: 'root'
})
export class FileuploadService {

  constructor(private http: HttpClient, private cS: ConnectionService, private gS: GlobalService) { }

  UploadFileok(file: File) {
    const httpOptions = {
      headers: new HttpHeaders({
        "Authorizat": this.gS.idGloba(this.cS.signConnection1()),
      })
    };

    const formData: FormData = new FormData();
    const url = `${this.cS.baseurl()}/api/v1/media`;
    formData.append('file', file);
    return this.http.post(url, formData, httpOptions);
  }

  UploadFileok1(file: File) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': "application/json",
        'Content-Type': 'multipart/form-data',
        'Authorizat': this.gS.idGloba(this.cS.signConnection1())
      })
    };
    const url = `${this.cS.baseurl()}/media`;
    const formData = new FormData();
    formData.append("file", file, file.name);
    return this.http.post(url, formData, httpOptions);
  }

  UploadFileString(imgBase64: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
      })
    };
    const url = `${base_url}/api/upload`;

    const file = this.DataURIToBlob(imgBase64)
    const formData = new FormData();
    formData.append('upload', file, 'image.jpg')
    return this.http.post(url, formData);
  }

  DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',')
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

    const ia = new Uint8Array(byteString.length)
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i)

    return new Blob([ia], { type: mimeString })
  }
  async uploadmedia(archivo: File) {
    try {

      const url = `${base_url}/api/upload`;
      const formData: FormData = new FormData();
      formData.append('file', archivo);

      const resp = await fetch(url, {
        method: 'POST',
        headers: {
          'Accept': "application/json",
          'Content-Type': 'multipart/form-data'
        },
        body: formData
      });

      const data = await resp.json();
      return data;
      // if ( data.internal_code==0 ) {
      //   return data.response_object.media_id;
      // } else {
      //   alert(data.message);

      // }

    } catch (error) {
      console.log(error);
      return false;
    }

  }
}
