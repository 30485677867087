import { Component, AfterViewInit, OnInit } from '@angular/core';
import { ROUTES } from './menu-items';
import { RouteInfo } from './sidebar.metadata';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UsuariosService } from 'src/app/services/usuarios.service';
import { GetGeneralPermissions } from 'src/app/interface/users.interface';
import { ConfirmationService } from 'primeng/api';
import { GlobalService } from 'src/app/services/global.service';
//declare var $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  showMenu = '';
  showSubMenu = '';
  permisos!:GetGeneralPermissions;
  public sidebarnavItems:RouteInfo[]=[];
  user:any;
  // this is for the open close
  addExpandClass(element: string) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }

  constructor(
    private gS: GlobalService,
    private router: Router,
    private route: ActivatedRoute,
    private sUser:UsuariosService,
    private confirmationService: ConfirmationService
  ) {}

  // End open close
  ngOnInit() {
    this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
    this.sGetUserFratures();
    this.user=JSON.parse(this.gS.getInformationUser);
  }
  sGetUserFratures() {
    this.sUser.getUserFratures()
      .subscribe((resp: any) => {
        if (resp.code === 200) {
          
          this.permisos = resp.message;
          // console.log(this.permisos);
          // console.log(this.permisos.permissions[0].SPE_CONSULTAR);
        }
        else {
          
          console.log( resp.message.err_code + ': ' + resp.message.err_text  );
        }
      });
  }
  cerrarSesion(){
    this.sUser.logout();
  }
  confirm() {
    this.confirmationService.confirm({
        message: '¿Estas seguro de cerrar tu sesión?',
        accept: () => {
          this.cerrarSesion();
          this.router.navigateByUrl('/auth/account');
        }
    });
}
}
